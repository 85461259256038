import { combineReducers } from '@reduxjs/toolkit';
import foodsSlice from './foodsSlice';
import loginSlice from './loginSlice';
import dayFoodsSlice from './dayFoodsSlice';
import settingsSlice from './settingsSlice';

const createReducer = asyncReducers =>
	combineReducers({
		foodsSlice,
		loginSlice,
		settingsSlice,
		dayFoodsSlice,
		...asyncReducers
	});

export default createReducer;
