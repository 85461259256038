import React from 'react';
import './header.css';

function Header(props) {
    return (
        <div className="header">
            {/* <img src="logo.svg" className="headerlogo" /> */}
        </div>
    )
}

export default Header;