import React from 'react';
import Chart from 'react-apexcharts'

function Gauge(props) {
  const options = {
    chart: {
      height: 150,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
         hollow: {
          margin: 0,
          size: '75%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.14
          }
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
    
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '12px'
          },
          value: {
            formatter: function(val) {
              return parseInt(val) + "%";
            },
            color: '#aaa',
            fontSize: '14px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#e79300'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: [props.label || 'Percent'],
  };

  const series = [
    props.value || 0
  ]
  
  return (
        <React.Fragment>
          <div className="card card-gauge">
            <div className="card-body">
              <Chart
                options={options} 
                series={series} 
                type="radialBar" 
                width={216} 
                height={170} />
            </div>
            <div className="gauge-details">{props.details || ''}</div>
          </div>
        </React.Fragment>
  )
}

export default Gauge;