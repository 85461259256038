import Joi from 'joi-browser';

// settings object schema validation
const schema = {
    percentProtein: Joi.number().required().label('Proteine (%)')
}

export default function validateSettings(settings) {
    const options = { abortEarly: false }
    const { error } = Joi.validate(settings, schema, options);

    // return empty object if there are no errors
    if(!error) return {};

    // prepare output object
    const errors = {};

    // prepare errors object
    for(let item of error.details)
        errors[item.path[0]] = item.message;

    return errors;
}