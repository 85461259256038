import * as common from './common';
import config from '../config';
import moment from 'moment';

// EXPORTED FUNCTIONS
export function getFoods() {
    return common.get(`${config.apibase}/api/foods`);
}

export function newFood(newFood) {
    return common.post(`${config.apibase}/api/foods`, newFood);
}

export function editFood(editFood) {
    return common.put(`${config.apibase}/api/foods/${editFood._id}`, editFood);
}

export function getDayFoods(date) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return common.get(`${config.apibase}/api/dayfoods/${formattedDate}`);
}

export function addDayFood(date, foodId, amount) {
    return common.post(`${config.apibase}/api/dayfoods/${date}`, {
        foodId: foodId,
        amount: amount
    });
}

export function removeDayFood(id) {
    return common.del(`${config.apibase}/api/dayfoods/${id}`);
}