import React, {useEffect, useState} from 'react';
import Input from "../../../components/input";

// ****************************************************************************************************************
// Events
// - amountChanged(amount)
// ****************************************************************************************************************

function PickDate(props) {
    const [amount, setAmount] = useState("0");

    function onAmountChanged(event) {
        const amount = event.target.value;
        if(props.amountChanged) props.amountChanged(amount);
        setAmount(amount);
    }

    // fire initial value
    useEffect(() => {
		if(props.amountChanged) props.amountChanged(0);
	}, []);

    const handleFocus = (event) => event.target.select();

    return (
        <React.Fragment>
            <div className="card picker-card">
                <div className="card-body">
                    <h5 className="card-title">Menge</h5>
                    <p className="card-text">
                        <Input 
                            label="Gramm"
                            onChange={onAmountChanged}
                            value={amount}
                            onFocus={handleFocus}
                        />
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PickDate;