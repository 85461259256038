import React from 'react';
import { Route, Redirect, Routes } from "react-router-dom";
import { NavLink } from "react-router-dom";
import './navbar.css'

function Navbar(props) {
    return (
        <nav className="navbar-primary">
            {/* HOME */}
            <NavLink to="/home" className="nav-item">
                <button className="btn btn-primary">Home</button>
            </NavLink>

            {/* MEMBERS */}
            <NavLink to="/foods" className="nav-item">
                <button className="btn btn-primary">Foods</button>
            </NavLink>
            
            {/* TRACKER */}
            <NavLink to="/tracker" className="nav-item">
                <button className="btn btn-primary">Tracker</button>
            </NavLink>

            {/* SETTING */}
            <NavLink to="/settings" className="nav-item">
                <button className="btn btn-primary">Einstellungen</button>
            </NavLink>

        </nav>
    )
}

export default Navbar;