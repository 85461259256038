import React, {useEffect} from 'react';
import useForm from '../../components/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Input from '../../components/input'
import validateMember from './common/validation';
import Select from '../../components/select';
import { useNavigate } from "react-router-dom";
import { getAllFoods } from '../../store/foodsSlice';
import { connect } from 'react-redux'
import * as _ from 'lodash';
import { editFood } from '../../store/foodsSlice';
import Tabs from './common/tabs';

function MemberDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { form, handleChange, handleSubmit, setForm, formErrors } = useForm({}, submitted, validateMember);
    const routeParams = useParams();

    useEffect(() => {
        // get member id to display
		const memberId = routeParams.id;
        // if members are not yet loaded => load
        if(props.members.length === 0) {
            dispatch(getAllFoods());
        } else {
            // find specific member
            const member = _.find(props.members, m => m._id == memberId);
            setForm(_.pick(member,["vorname","nachname","wohnort","plz","gender","email", "strasse","badgenumber","_id"]))
        }
	}, [dispatch, routeParams, props.members]);

    // form submitted
    function submitted() {
        dispatch(editFood(form));
        navigate("/members", { replace: true });
	}

    return(
        <React.Fragment>
            <h1>Mitglied Details</h1>
            <Tabs>
                <div title="test">
                    <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                    
                    {/* BADGENUMBER */}
                    <Input 
                        value={form.badgenumber}
                        error={formErrors.badgenumber}
                        onChange={handleChange}
                        label="badgenumber" 
                        id="badgenumber"
                        name="badgenumber" />

                    {/* VORNAME */}
                    <Input 
                        value={form.vorname}
                        error={formErrors.vorname}
                        onChange={handleChange}
                        label="vorname" 
                        id="vorname"
                        name="vorname" />
                        
                    {/*NACHNAME*/}
                    <Input 
                        value={form.nachname}
                        error={formErrors.nachname}
                        onChange={handleChange}
                        label="nachname" 
                        id="nachname"
                        name="nachname" />

                    {/*EMAIL*/}
                    <Input 
                        value={form.email}
                        error={formErrors.email}
                        onChange={handleChange}
                        label="email" 
                        id="email"
                        name="email" />

                    {/* GENDER */}
                    <Select
                        id="gender"
                        name="gender"
                        label="geschlecht"
                        value={form.gender}
                        onChange={handleChange}
                        options={[
                            {_id: false, name: "weiblich"},
                            {_id: true, name: "männlich"}
                        ]}
                    />

                    {/* STRASSE */}
                    <Input 
                        value={form.strasse}
                        onChange={handleChange}
                        label="strasse" 
                        id="strasse"
                        name="strasse" />

                    {/* PLZ */}
                    <Input 
                        value={form.plz}
                        onChange={handleChange}
                        error={formErrors.plz}
                        label="plz" 
                        id="plz"
                        name="plz" />

                    {/* WOHNORT */}
                    <Input 
                        value={form.wohnort}
                        onChange={handleChange}
                        label="wohnort" 
                        id="wohnort"
                        name="wohnort" />

                    {/* SAVE */}
                    <input 
                        type="submit" 
                        value="Speichern"
                        className="btn btn-primary" />
                    </form>
                </div>
                <div title="test 2">asdassdfasdfadsfsdfadjoi</div>
                <div title="test 3">asdsfdsfsdfsdfsdfdassdfasdfadsfsdfadjoi</div>
            </Tabs>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { members: state.membersSlice.members }
}
  
export default connect(mapStateToProps)(MemberDetails)