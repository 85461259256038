import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as _ from "lodash";
import { 
	getFoods as serviceGetFoods,
	newFood as serviceCreateFood,
	editFood as serviceEditFood
} from '../services/foods';

const initialState = {
	foods: [],
	filteredFoods: []
};

export const getAllFoods = () => async dispatch => {
	const allFoods = await serviceGetFoods();

	const sorted = _.sortBy(allFoods, 'name');

	dispatch(setFilteredFoods(sorted))
    return dispatch(setFoods(sorted));
};

export const FoodMember = createAsyncThunk("foodsSlice/foods/saveNewFood", async (newfood, {dispatch, getState}) => {	
	// create member in backend
	const createdFood = await serviceCreateFood(newfood).catch(err => {
		console.log(err)
		return;
	});
	// add to redux store
	dispatch(addFood(createdFood));
});

export const editFood = createAsyncThunk("foodsSlice/foods/editFood", async (changedFood, {dispatch, getState}) => {	
	// create food in backend
	const editedFood = await serviceEditFood(changedFood).catch(err => {
		console.log(err)
		return;
	});
	// add to redux store
	dispatch(changedFood(editedFood));
});

export const saveNewFood = createAsyncThunk("foodsSlice/foods/newFood", async (food, {dispatch, getState}) => {	
	// create food in backend
	const result = await serviceCreateFood(food).catch(err => {
		console.log(err)
		return;
	});
	// add to redux store
	dispatch(addFood(food));
});

export const search = createAsyncThunk("foodsSlice/foods/search", async (searchText, {dispatch, getState}) => {	
	const state = await getState();
	if(searchText === "") {
		dispatch(setFilteredFoods(state.foodsSlice.foods))
	}else{
		const filteredFoods = _.filter(state.foodsSlice.foods, m => { 
			if(!m.name) return false
			return m.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
		});
		dispatch(setFilteredFoods(filteredFoods));
	}
});

const foodsSlice = createSlice({
	name: 'foods/manage',
	initialState,
	reducers: {
		setFoods: (state, action) => {
			return {...state, foods: action.payload};
		},
		setFilteredFoods: (state, action) => {
			return {...state, filteredFoods: action.payload};
		},
		addFood: (state, action) => {
			return {...state, foods: [...state, action.payload]};
		},
		removeFood: (state, action) => {
			return {...state, foods: _.filter(state, s => s._id != action.payload._id)}
		},
		changeFood: (state, action) => {
			return {...state, foods: _.map(state, s=> { return s._id == action.payload._id ? action.payload : s })}
		}
	}
});

export const { setFoods, addFood, removeFood, changeFood, setFilteredFoods } = foodsSlice.actions;

export default foodsSlice.reducer;
