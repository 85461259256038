import DatePicker from "react-datepicker";
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

// ****************************************************************************************************************
// Events
// - dateChanged(date)
// ****************************************************************************************************************

function PickDate(props) {
    const [startDate, setStartDate] = useState(new Date());
    const routeParams = useParams();

    function onDateChanged(date) {
        if(props.dateChanged) props.dateChanged(date);
        setStartDate(date);
    }

    // fire initial value
    useEffect(() => {
		if(props.dateChanged) props.dateChanged(startDate);
	},[routeParams]);

    return (
        <React.Fragment>
            <div className="card picker-card">
                <div className="card-body">
                    <h5 className="card-title">Datum</h5>
                    <p className="card-text">
                        <label>&nbsp;</label>
                        <DatePicker 
                            selected={startDate} 
                            value={startDate}
                            onChange={(date) => onDateChanged(date)} 
                            dateFormat="dd.MM.yyyy"
                            className="form-control" />
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PickDate;