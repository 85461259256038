import logo from './logo.svg';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import Navbar from './modules/navbar/navbar';
import Settings from './modules/settings/settings';
import Checkin from './modules/checkin/checkin';
import LoginController from './modules/login/controller';
import Foods from './modules/foods/foods';
import FoodDetails from './modules/fooddetails/fooddetails';
import Tracker from './modules/tracker/tracker';
import MemberDetails from './modules/foods/memberdetails';
import Header from './modules/header/header';
import { Provider } from 'react-redux'
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import React from 'react';
import store from './store';
import NewFood from './modules/foods/newfood';

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Router>
          <LoginController>
          <div className="container">
            <Navbar />
            <div className="content">
              <Header />
              <Routes>
                <Route path="/foods/newfood" element={ <NewFood /> } />
                <Route path="/members/:id" element={ <MemberDetails /> } />
                <Route path="/foods/:id" element={ <FoodDetails /> } />
                <Route path="/foods" element={ <Foods /> } />
                <Route path="/settings" element={ <Settings /> } />
                <Route path="/tracker" element={ <Tracker /> } />
                <Route path="/checkin" element={ <Checkin /> } />
                <Route path="/" element={ <Foods /> } />
              </Routes>
            </div>
          </div>
          </LoginController>
        </Router>
      </Provider>
    </React.Fragment>
  );
}

export default App;
