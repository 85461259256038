import React from 'react';
import { connect } from 'react-redux'
import * as _ from 'lodash'
import { NavLink } from "react-router-dom";
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        format: (row, index) => { 
            return <NavLink to={`/foods/${row.Id}`} style={{textDecoration: "none"}}><a >{row.name}</a></NavLink>
        }
    },
    {
        name: 'Shop',
        selector: row => row.store,
        sortable: true,
    },
    {
        name: 'KCAL',
        selector: row => row.kcal,
        sortable: true,
    },
    {
        name: 'Proteine',
        selector: row => row.protein,
        sortable: true,
    },
    {
        name: 'Carbs',
        selector: row => row.carbs,
        sortable: true,
    },
    {
        name: 'Fat',
        selector: row => row.fat,
        sortable: true,
    }
];


function FoodsList(props) {
    return (
        <React.Fragment>
            <DataTable
                columns={columns}
                data={props.foods}
                pagination
                persistTableHead
            />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { foods: state.foodsSlice.filteredFoods }
  }
  
export default connect(mapStateToProps)(FoodsList)