const dev = {
    apibase: "https://fitness-backend.services.opensight.ch",
    oauth: {
        token_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/token",
        auth_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/auth",
        logout_url: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/logout",
        redirect_uri: "http://localhost:3000",
        client_id: "fitness",
    }
}

const prod = {
    apibase: "https://fitness-backend.services.opensight.ch",
    oauth: {
        token_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/token",
        auth_endpoint: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/auth",
        logout_url: "https://auth.opensight.ch/auth/realms/master/protocol/openid-connect/logout",
        redirect_uri: `https://fitness.services.opensight.ch`,
        client_id: "fitness",
    }
}

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
    ...config
}
