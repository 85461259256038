import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as _ from "lodash";
import { 
	getSettings as serviceGetAllSettings,
} from '../services/settings';

const initialState = {
	proteinGramm: 150,
	carbsGramm: 385,
    fatGramm: 75,
    kcal: 2891,
    gewicht: 75,
    pal: 5,
    percentFat: 12,
    percentCarbs: 63,
    percentProtein: 25
};

export const getAllSettings = () => async dispatch => {
	const allSettings = await serviceGetAllSettings();
    return dispatch(setSettings(allSettings));
};

const settingsSlice = createSlice({
	name: 'settings/manage',
	initialState,
	reducers: {
		setSettings: (state, action) => {
			return action.payload;
		}
	}
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
