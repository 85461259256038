import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux';
import { interceptToken, checkTokenExpired } from '../../store/loginSlice';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import {getAllSettings} from 'store/settingsSlice';


function redirectToIdentityProvider(currentLocation) {
    // navigate to login
    const return_url = `${config.oauth.redirect_uri}/${currentLocation}`;
    window.location.href = `${config.oauth.auth_endpoint}?scopes=openid profile roles&response_type=token&client_id=${config.oauth.client_id}&redirect_uri=${return_url}`;
}

function LoginController(props) {
    const [whitelistetLocation, setWhitelistetLocation] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const routeParams = useParams();

    useEffect(() => {
        const whitelist_locations = [
            "/checkoutsuccess",
            "/checkoutcancelled",
            "/logout"
        ]

        // skip token interception if location is whitelisted
        if(whitelist_locations.indexOf(location.pathname) > -1) {
            setWhitelistetLocation(true);
            return
        }
        setWhitelistetLocation(false);

        // intercept token
        if(!props.loggedIn) {
            // intercept token after redirection
            dispatch(interceptToken({ 
                params: location, 
                redirectCallback: () => { redirectToIdentityProvider(location.pathname) },
                redirectToLastPath: () => { navigate(window.location.pathname + window.location.search) }
            }));
        }else{
            // re-login when token is expired
            dispatch(checkTokenExpired());
            dispatch(getAllSettings());
        }
	}, [
        routeParams, 
        dispatch, 
        location, 
        props.loggedIn]);

    return (
        <React.Fragment>
            {(props.loggedIn || whitelistetLocation) && props.children}
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return { loggedIn: state.loginSlice.loggedIn }
}
  
export default connect(mapStateToProps)(LoginController)