import React, {useEffect, useRef} from 'react';
import withReducer from '../../store/withReducer';
import reducer from '../../store';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getAllFoods } from '../../store/foodsSlice';
import { NavLink } from "react-router-dom";
import FoodsList from './foodlist';
import { search } from '../../store/foodsSlice';

function Foods(props){
    const dispatch = useDispatch();
	const routeParams = useParams();
    const searchfield = useRef();

    useEffect(() => {
		dispatch(getAllFoods());
	}, [routeParams]);

    function onSearchChanged() {
        dispatch(search(searchfield.current.value))
    }

    return (
        <React.Fragment>
            <h1>Nahrungsmittel</h1>
            <NavLink to="newfood">
                <button className="btn btn-primary">Neu</button>
            </NavLink>
            <input 
                type="text" 
                className="form-input" 
                id="search" 
                style={{float: "right"}} 
                autoFocus
                ref={searchfield}
                onChange={onSearchChanged}
                />
            <FoodsList />
        </React.Fragment>
    )
}

export default withReducer('foods', reducer)(Foods);