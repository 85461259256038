import React, {useEffect, useState} from 'react';
import Select from "../../../components/select";
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux';
import { getAllFoods } from '../../../store/foodsSlice';
import { useParams } from 'react-router-dom';

// ****************************************************************************************************************
// Events
// - foodChanged()
// ****************************************************************************************************************

function PickFood(props) {
    const [foodId, setFoodId] = useState(null);
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    const routeParams = useParams();

    function onFoodChanged(event) {
        const foodId = event.target.value;
        if(props.foodChanged) props.foodChanged(foodId);
        setFoodId(foodId);
    }

    useEffect(() => {
        dispatch(getAllFoods());
	}, []);

    useEffect(() => {
		setOptions(props.foods.map((food) => {
            return {
                _id: food.Id,
                name: food.name,
            }
        }));
	}, [props.foods]);

    useEffect(() => {
        if(props.foodChanged && options.length > 0) props.foodChanged(options[0]._id);
	}, [options]);

    return (
        <React.Fragment>
            <div className="card picker-card">
                <div className="card-body">
                    <h5 className="card-title">Nahrungsmittel</h5>
                    <p className="card-text">
                        <Select 
                            options={options} 
                            name="food"
                            label="Name"
                            value={foodId}
                            onChange={onFoodChanged} />
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { foods: state.foodsSlice.foods }
  }
  
export default connect(mapStateToProps)(PickFood)