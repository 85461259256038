import React from 'react';

// ****************************************************************************************************************
// Events
// - onClickSave()
// ****************************************************************************************************************

function PickSave(props) {

    function onSave() {
        if(props.onClickSave) props.onClickSave();
    }

    return (
        <React.Fragment>
            <div className="card picker-card">
                <div className="card-body">
                    <h5 className="card-title">Speichern</h5>
                    <p className="card-text">
                        <label>&nbsp;</label><br />
                        <button 
                            className="btn btn-primary"
                            onClick={onSave}
                        >Speichern</button>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PickSave;