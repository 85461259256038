import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import PickDate from "../common/pickdate/pickdate";
import Pickfood from "../common/pickfood/pickfood";
import PickAmount from "../common/pickamount/pickamount";
import PickSave from "../common/picksave/picksave";
import DayFoods from "../dayfoods/dayfoods";
import { addNewDayFood } from "../../store/dayFoodsSlice";

function Tracker(props) {
    const dispatch = useDispatch();

    const [pickedValues, setPickedValues] = useState({
        amount: 0,
        food: null,
        date: new Date()
    });

    function onSaveDayFood() {
        console.log("dispatching", pickedValues)
        dispatch(addNewDayFood({
            date:new Date(pickedValues.date), 
            foodId: pickedValues.food, 
            amount: pickedValues.amount
        }));
    }

    return (
        <React.Fragment>
            <h1>Tracker</h1><br />
            
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <PickDate 
                            dateChanged={(prop) => {setPickedValues({...pickedValues, date: prop})}} 
                        />
                    </div>
                    <div className="col-sm">
                        <Pickfood 
                            foodChanged={(prop) => {setPickedValues({...pickedValues, food: prop})}}
                        />
                    </div>
                    <div className="col-sm">
                        <PickAmount 
                            amountChanged={(prop) => {setPickedValues({...pickedValues, amount: prop})}}
                        />
                    </div>
                    <div className="col-sm">
                        <PickSave 
                            onClickSave={onSaveDayFood}
                        />
                    </div>
                </div>
            </div>
            <DayFoods date={pickedValues.date} />
        </React.Fragment>
    )
}

export default Tracker;