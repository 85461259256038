import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component';
import { getAllDayFoods, removeDayFood } from "../../store/dayFoodsSlice";
import { useParams } from 'react-router-dom';
import Gauge from "../common/gauge/gauge";
import * as _ from 'lodash';
import { NavLink } from "react-router-dom";
const { getAllSettings } = require('../../store/settingsSlice');

function DayFoods(props) {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const [sums, setSums] = useState([]);
    const [bedarf, setBedarf] = useState([]);

    const columns = [
        {
            name: 'name',
            selector: row => row.food.name,
            sortable: true,
            format: (row, index) => { 
                return <NavLink to={`/foods/${row.food.Id}`} style={{textDecoration: "none"}}><a >{row.food.name}</a></NavLink>
            }
        },
        {
            name: 'Menge (g)',
            selector: row => parseInt(row.grams),
            sortable: true
        },
        {
            name: 'KCal',
            selector: row => parseInt(row.food.kcal) * parseInt(row.grams) / 100,
            sortable: true
        },
        {
            name: 'Proteine (g)',
            selector: row => parseInt(row.food.protein) * parseInt(row.grams) / 100,
            sortable: true
        },
        {
            name: 'Carbs (g)',
            selector: row => parseInt(row.food.carbs) * parseInt(row.grams) / 100,
            sortable: true
        },
        {
            name: 'Fat (g)',
            selector: row => parseInt(row.food.fat) * parseInt(row.grams) / 100,
            sortable: true
        },
        {
            name: '',
            selector: row => row.Id,
            format: (row, index) => { 
                return <button 
                    class="btn btn-primary" 
                    onClick={() => remove(row.id)}
                    style={{width: 120, marginTop: 10}}>Entfernen</button>
            }
        }
    ];

    useEffect(() => {
        if(!props.date) return
		dispatch(getAllDayFoods(props.date));
        dispatch(getAllSettings());

        if(!props.settings || props.settings.length == 0) return
        // get kcal bedarf from settings
        const settings_kcal = _.find(props.settings, s=> { return s.name == "kcal"})
        const settings_proteine = _.find(props.settings, s=> { return s.name == "protein"})
        const settings_carbs = _.find(props.settings, s=> { return s.name == "carbs"})
        const settings_fat = _.find(props.settings, s=> { return s.name == "fat"})
        setBedarf({
            kcal: settings_kcal ? settings_kcal.value : 0,
            proteine: settings_proteine ? settings_proteine.value : 0,
            carbs: settings_carbs ? settings_carbs.value : 0,
            fat: settings_fat ? settings_fat.value : 0,
        })
	}, [props.date, routeParams]);

    // calculate sums
    useEffect(() => {
        const kcal = _.sumBy(props.dayFoods, df => df.food.kcal * df.grams / 100);
        const proteine = _.sumBy(props.dayFoods, df => df.food.protein * df.grams / 100);
        const carbs = _.sumBy(props.dayFoods, df => df.food.carbs * df.grams / 100);
        const fat = _.sumBy(props.dayFoods, df => df.food.fat * df.grams / 100);
        setSums({
            kcal: kcal,
            proteine: proteine,
            carbs: carbs,
            fat: fat,
            kcalPercent: (100 / parseInt(bedarf.kcal) * parseInt(kcal)),
            proteinePercent: (100 / parseInt(bedarf.proteine) * parseInt(proteine)),
            carbsPercent: (100 / parseInt(bedarf.carbs) * parseInt(carbs)),
            fatPercent: (100 / parseInt(bedarf.fat) * parseInt(fat)),
        });
	}, [props.dayFoods, bedarf]);

    function remove(id) {
        dispatch(removeDayFood(id));
    }


    return (
        <React.Fragment>
            {/* GAUGES */ }
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <Gauge label="KCal" value={sums.kcalPercent} details={(sums.kcal + " kcal / " + bedarf.kcal + " kcal")} />
                    </div>
                    <div className="col-sm">
                        <Gauge label="Proteine" value={sums.proteinePercent} details={(parseInt(sums.proteine) + " g / " + bedarf.proteine + " g")} />
                    </div>
                    <div className="col-sm">
                        <Gauge label="Carbs" value={sums.carbsPercent} details={(parseInt(sums.carbs) + " g / " + bedarf.carbs + " g")} />
                    </div>
                    <div className="col-sm">
                        <Gauge label="Fat" value={sums.fatPercent} details={(parseInt(sums.fat) + " g / " + bedarf.fat + " g")} />
                    </div>
                </div>
            </div>
            
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Day Foods</h5>
                    <DataTable
                        columns={columns}
                        data={props.dayFoods}
                        pagination
                        persistTableHead
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { 
        dayFoods: state.dayFoodsSlice.dayFoods,
        settings: state.settingsSlice
    }
  }
  
export default connect(mapStateToProps)(DayFoods)