import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as _ from "lodash";
import moment from 'moment';
import { 
	getDayFoods as serviceGetDayFoods,
	addDayFood as serviceAddDayFood,
	removeDayFood as serviceRemoveDayFood
} from '../services/foods';

const initialState = {
	dayFoods: []
};

export const getAllDayFoods = createAsyncThunk("dayFoodsSlice/dayFoods/get", async (date, {dispatch, getState}) => {	
	const allDayFoods = await serviceGetDayFoods(date);
	console.log("get dayfoods for date",date)
    return dispatch(setDayFoods(allDayFoods));
});

export const addNewDayFood = createAsyncThunk("dayFoodsSlice/dayFoods/saveNewFood", async ({date, foodId, amount}, {dispatch, getState}) => {	
	// create member in backend
	const formattedDate = moment(date).format('YYYY-MM-DD');
	const createdFood = await serviceAddDayFood(formattedDate, foodId, amount).catch(err => {
		console.log(err)
		return;
	});
	// add to redux store
	dispatch(getAllDayFoods(date));
});

export const removeDayFood = createAsyncThunk("dayFoodsSlice/dayFoods/remove", async (id, {dispatch, getState}) => {	
	await serviceRemoveDayFood(id)
	// add to redux store
	dispatch(removeFood(id));
});

const dayFoodsSlice = createSlice({
	name: 'dayfoods/manage',
	initialState,
	reducers: {
		setDayFoods: (state, action) => {
			return {...state, dayFoods: action.payload};
		},
		removeFood: (state, action) => {
			return {...state, dayFoods: state.dayFoods.filter(dayFood => dayFood.id !== action.payload)};
		}
	}
});

export const { setDayFoods, removeFood } = dayFoodsSlice.actions;

export default dayFoodsSlice.reducer;
