import { useCallback, useEffect, useState } from 'react';
import './tabs.css'
import * as _ from 'lodash';

function Tabs(props) {
    const [activeTab, setActiveTab] = useState(0);

    function onTabClick(el) {
        setActiveTab(parseInt(el.target.title))
        console.log(activeTab)
    }

    useEffect(() => {
        console.log(props.children)
	}, [activeTab]);

    return (
        <div className="page-content">
            <div className="tabbed">
                {
                    _.map(props.children, (c, index) => (
                        <input type="radio" name="css-tabs" checked={activeTab == index} key={index}/>
                    ))
                }
                <ul className="tabs">
                {
                    _.map(props.children, (c, index) => (
                        <li className="tab" key={index}>
                            <label title={index} onClick={onTabClick}>{c.props.title}</label>
                        </li>
                    ))
                }
                </ul>
                {
                    _.map(props.children, (c, index) => (
                        <div className="tab-content" key={index}>
                            { c }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Tabs;