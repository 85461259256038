import Joi from 'joi-browser';

// user object schema validation
const schema = {
    name: Joi.string().required().label('Name'),
    store: Joi.string().required().label('Store'),
    protein: Joi.string().required().label('protein'),
    fat: Joi.string().required().label('fat'),
    carbs: Joi.string().required().label('carbs'),
    kcal: Joi.string().required().label('kcal'),
}


export default function validateMember(member) {
    const options = { abortEarly: false }
    const { error } = Joi.validate(member, schema, options);

    // return empty object if there are no errors
    if(!error) return {};

    // prepare output object
    const errors = {};

    // prepare errors object
    for(let item of error.details)
        errors[item.path[0]] = item.message;

    return errors;
}
