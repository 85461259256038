import React from 'react';
import useForm from '../../components/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { saveNewFood } from '../../store/foodsSlice';
import { useNavigate } from "react-router-dom";
import validateMember from './common/validation';
import Input from '../../components/input'
import Select from '../../components/select';

function NewFood(props) {
    const navigate = useNavigate();
	const dispatch = useDispatch();
    const { form, handleChange, setForm, handleSubmit, formErrors } = useForm({
        name: '',
        store: '',
        kcal: '',
        carbs: '',
        protein: ''
    }, submitted, validateMember);

    function submitted() {
        dispatch(saveNewFood(form));
        navigate("/members", { replace: true });
	}

    return (
        <React.Fragment>
            <h1>Neues Nahrungsmittel</h1>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                
                {/* NAME */}
                <Input 
                    value={form.name}
                    error={formErrors.name}
                    onChange={handleChange}
                    label="name" 
                    id="name"
                    name="name" />

                {/* STORE */}
                <Input 
                    value={form.store}
                    onChange={handleChange}
                    error={formErrors.store}
                    label="store" 
                    id="store"
                    name="store" />
                    
                {/*KCAL*/}
                <Input 
                    value={form.kcal}
                    error={formErrors.kcal}
                    onChange={handleChange}
                    label="kcal" 
                    id="kcal"
                    name="kcal" />

                {/*PROTEIN*/}
                <Input 
                    value={form.protein}
                    error={formErrors.protein}
                    onChange={handleChange}
                    label="protein" 
                    id="protein"
                    name="protein" />

                {/* CARBS */}
                <Input
                    id="carbs"
                    name="carbs"
                    label="carbs"
                    value={form.carbs}
                    onChange={handleChange}
                />

                {/* FAT */}
                <Input 
                    value={form.fat}
                    onChange={handleChange}
                    label="fat" 
                    id="fat"
                    name="fat" />

               <input type="submit" className="btn btn-primary" value="Speichern" />
               {JSON.stringify(formErrors)}
               {JSON.stringify(form)}
            </form>
        </React.Fragment>
    )

}

export default NewFood;