import React, { useState, useEffect } from "react";
import useForm from '../../components/useForm';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import validate from '../foods/common/validation';
import Input from '../../components/input'

function FoodDetails(props) {
    const routeParams = useParams();

    const { form, handleChange, setForm, handleSubmit, formErrors } = useForm({
        name: '',
        store: '',
        kcal: '',
        carbs: '',
        protein: ''
    }, submitted, validate);

    useEffect(() => {
        const foodId = routeParams.id;
        const food = _.find(props.foods, f => {return f.Id == foodId});
        setForm(food);
	}, [routeParams]);

    function submitted() {
	}

    return (
        <div>
            <h1>Food Details</h1>
            <form noValidate onSubmit={handleSubmit} className="flex flex-col md:overflow-hidden">
                
                {/* NAME */}
                <Input 
                    value={form.name}
                    error={formErrors.name}
                    onChange={handleChange}
                    label="name" 
                    id="name"
                    name="name" />

                {/* STORE */}
                <Input 
                    value={form.store}
                    onChange={handleChange}
                    error={formErrors.store}
                    label="store" 
                    id="store"
                    name="store" />
                    
                {/*KCAL*/}
                <Input 
                    value={form.kcal}
                    error={formErrors.kcal}
                    onChange={handleChange}
                    label="kcal" 
                    id="kcal"
                    name="kcal" />

                {/*PROTEIN*/}
                <Input 
                    value={form.protein}
                    error={formErrors.protein}
                    onChange={handleChange}
                    label="protein" 
                    id="protein"
                    name="protein" />

                {/* CARBS */}
                <Input
                    id="carbs"
                    name="carbs"
                    label="carbs"
                    value={form.carbs}
                    onChange={handleChange}
                />

                {/* FAT */}
                <Input 
                    value={form.fat}
                    onChange={handleChange}
                    label="fat" 
                    id="fat"
                    name="fat" />

               <input type="submit" className="btn btn-primary mt-3" value="Speichern" />
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return { foods: state.foodsSlice.foods }
}
  
export default connect(mapStateToProps)(FoodDetails)