import React, { useState, useEffect } from "react";
import useForm from '../../components/useForm';
import Input from '../../components/input'
import validateSettings from './common/validation';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
const { getAllSettings } = require('../../store/settingsSlice');
const _ = require('lodash');

function Settings(props) {
    const routeParams = useParams();
    const dispatch = useDispatch();

    const { form, handleChange, setForm, handleSubmit, formErrors } = useForm({
        percentProtein: '',
        percentCarbs: '',
        percentFat: '',
        kcal: '',
        pal: '',
        gewicht: ''
    }, submitted, validateSettings);

    useEffect(() => {
      dispatch(getAllSettings());
	}, [routeParams]);

    useEffect(() => {
        setForm({
            percentProtein: _.find(props.settings, s => s.name=="percent_protein").value,
            percentCarbs: _.find(props.settings, s => s.name=="percent_carbs").value,
            percentFat: _.find(props.settings, s => s.name=="percent_fat").value,
            kcal: _.find(props.settings, s => s.name=="kcal").value,
            pal: _.find(props.settings, s => s.name=="pal").value,
            gewicht: _.find(props.settings, s => s.name=="gewicht").value,
        });
    }, [props.settings]);

    function submitted() {
        // to do: submit to server
	}

    return (
        <React.Fragment>
            <h1>Einstellungen</h1>

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Bedarf</h5>
                    {/* KCal */}
                    <Input 
                    value={form.kcal}
                    error={formErrors.kcal}
                    onChange={handleChange}
                    label="KCal" 
                    id="kcal"
                    name="kcal" />
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Aufteilung</h5>
                    {/* Prozent Protein */}
                    <Input 
                    value={form.percentProtein}
                    error={formErrors.percentProtein}
                    onChange={handleChange}
                    label="Proteine (%)" 
                    id="percentProtein"
                    name="percentProtein" />

                    {/* Prozent Carbs */}
                    <Input 
                    value={form.percentCarbs}
                    error={formErrors.percentCarbs}
                    onChange={handleChange}
                    label="Carbs (%)" 
                    id="percentCarbs"
                    name="percentCarbs" />

                    {/* Prozent Fat */}
                    <Input 
                    value={form.percentFat}
                    error={formErrors.percentFat}
                    onChange={handleChange}
                    label="Fat (%)" 
                    id="percentFat"
                    name="percentFat" />
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Persönliche Angaben</h5>
                    {/* PAL */}
                    <Input 
                    value={form.PAL}
                    error={formErrors.PAL}
                    onChange={handleChange}
                    label="PAL" 
                    id="PAL"
                    name="PAL" />

                    {/* Gewicht */}
                    <Input 
                    value={form.gewicht}
                    error={formErrors.gewicht}
                    onChange={handleChange}
                    label="Gewicht" 
                    id="gewicht"
                    name="gewicht" />
                </div>
            </div>

            
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return { settings: state.settingsSlice }
  }
  
export default connect(mapStateToProps)(Settings)